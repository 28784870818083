import { convertUnicodeToShiftJIS, transpose } from '../common'

export const exportMixin = {
  methods: {
    exportCSVMixins(fileName = 'data', data) {
      fileName = fileName + '.csv'
      const encodedData = convertUnicodeToShiftJIS(data)
      const blob = new Blob([encodedData], {
        type: 'text/csv'
      })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('charset', 'Shift_JIS')
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    },
    async exportZipMixins(data) {
      const fileName = 'result.zip'
      const blob = new Blob([data], {
        type: 'application/zip'
      })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    },
    createArrayCsvRecommendMixins(data) {
      const size = Object.keys(data).length
      let result = []
      if (size === 1) { // 1 category 100 characters
        const category = Object.keys(data)[0]
        const dataFormat = {}
        for (const [indexCharacter, value] of Object.entries(data[category])) {
          const objCharacter = {}
          objCharacter[category] = value
          dataFormat[indexCharacter] = objCharacter
        }
        result = this.slideArrayForCsv(dataFormat)
      } else { // 1 character 100 categories
        result = this.slideArrayForCsv(data)
      }
      return result
    },
    slideArrayForCsv(data) {
      const count = Math.ceil(Object.keys(data).length / 25)
      let result = []
      for (let i = 1; i <= count; i++) {
        const from = (i - 1) * 25
        const to = i * 25
        const sliced = Object.keys(data).slice(from, to).reduce((r, key) => {
          r[key] = data[key]
          return r
        }, {})
        const arraySlided = this.createArrayCSVMixins(sliced)
        result = result.concat(arraySlided)
      }
      return result
    },
    createArrayCSVMixins(data) {
      const arrayData = []
      const arrayColumn = ['']
      const arrayRow = []
      const result = []
      for (const [indexColumn, columns] of Object.entries(data)) {
        if (!arrayColumn.includes(indexColumn)) {
          arrayColumn.push(indexColumn)
        }
        const arrayTemp = []
        for (const [indexRow, row] of Object.entries(columns)) {
          if (!arrayRow.includes(indexRow)) {
            arrayRow.push(indexRow)
          }
          arrayTemp.push(row)
        }
        arrayData.push(arrayTemp)
      }
      const arrayTranspose = transpose(arrayData)

      arrayRow.forEach((value, index) => {
        const arrayValue = arrayTranspose[index]
        arrayValue.unshift(value)
        result.push(arrayValue)
      })
      result.unshift(arrayColumn)
      return result
    },
    formatDataPdfMixins(dataChart, menu, title) {
      const result = []
      const countChart = Math.ceil(dataChart.length / 25)

      const arrayText = []
      const arrayRank = []
      const arrayPercent = []
      dataChart.forEach(item => {
        arrayText.push(item.text)
        arrayRank.push(item.rank)
        arrayPercent.push(item.percent)
      })

      for (let i = 1; i <= countChart; i++) {
        const from = (i - 1) * 25
        const to = i * 25
        result.push({
          title: title,
          text: arrayText.slice(from, to),
          rank: arrayRank.slice(from, to),
          percent: arrayPercent.slice(from, to)
        })
      }

      return result
    }
  }
}
